











import {Popularize} from "@/request/data-center/Popularize";

import {Vue, Component} from "vue-property-decorator";
import {StoreInfo} from "@/request/merchant/StoreInfo";

@Component({
    name: 'data_popularize'
})
export default class data_popularize extends Vue {
    modal: any = Popularize;
    search: Array<any> = [
        {
            type: 'RadioGroup',
            label: '统计方式',
            key: 'statType',
            value: 0,
            items: [
                {
                    label: '按员工',
                    value: 0
                },
                {
                    label: '按店铺',
                    value: 1
                }
            ]
        },
        {
            type: 'Select',
            label: '门店',
            key: 'storeId',
            width: '160px',
            clearable: true,
            items() {
                return new Promise(resolve => {
                    StoreInfo.getAll().then((body: any) => {
                        let data: any = body.data;
                        resolve(data.map((j: any) => {
                            return {
                                label: j.name,
                                value: j.id
                            }
                        }));
                    })
                })
            }
        },
        {
            type: 'DatePicker',
            pickerType: 'month',
            label: '日期',
            key: 'months',
            width: '210px',
            multiple: true,
            placeholder: '请选择日期'
        }
    ];
    columns: Array<any> = [];
}

